import React from "react";
import type { IconName } from "@blueprintjs/icons";
import type { IBreadcrumbProps, MaybeElement } from "@blueprintjs/core";
import { Boundary, Breadcrumbs } from "@blueprintjs/core";
import style from "./MultiTabsLayout.module.css";
import SdButton from "../theming/SdButton";
import Tabs from "../theming/Tabs";
import type { TabProps } from "../theming/Tabs/Tabs";

type Action = {
  onClick: () => void;
  label: string;
  icon?: IconName | MaybeElement;
  display?: boolean;
  isRiskAction?: boolean;
};

type MultiTabsLayoutProps = {
  tabs: TabProps[];
  title: string;
  headingNode?: React.ReactNode;
  status?: React.ReactNode;
  onDelete?: () => void;
  onEdit?: () => void;
  actions?: Action[];
  children?: React.ReactNode;
  breadcrumbs?: IBreadcrumbProps[];
};

const MultiTabsLayout: React.FC<MultiTabsLayoutProps> = ({
  tabs,
  title,
  status,
  headingNode,
  onEdit,
  onDelete,
  children,
  actions,
  breadcrumbs,
}) => (
  <div>
    <div className={style.topBar}>
      <div>
        {breadcrumbs && (
          <Breadcrumbs
            collapseFrom={Boundary.START}
            items={breadcrumbs}
            className={style.breadcrumbs}
          />
        )}
        <div className="flex items-center">
          <div className={style.title}>{title}</div>
          {status && <div>{status}</div>}
        </div>
        {headingNode}
      </div>
      <div className={style.actions}>
        {onEdit && (
          <SdButton className={onDelete && "mr-2"} onClick={onEdit} icon="edit">
            Edit
          </SdButton>
        )}
        {onDelete && (
          <SdButton onClick={onDelete} icon="trash">
            Delete
          </SdButton>
        )}
        {actions &&
          actions.map((action, idx) =>
            action.display ? (
              <SdButton
                key={action.label}
                onClick={action.onClick}
                className={idx > 0 && idx < actions.length ? "mr-2" : ""}
                icon={action.icon}
                danger={action.isRiskAction}
              >
                {action.label}
              </SdButton>
            ) : null
          )}
      </div>
    </div>
    {children}
    {tabs.length > 0 && (
      <Tabs tabs={tabs} defaultSlug={tabs.length > 0 ? tabs[0].slug : ""} />
    )}
  </div>
);
export default MultiTabsLayout;
