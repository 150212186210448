type PageName = string;

export type EventName = string;

export const OPEN_IN_NEW_TAB: PageName = "_blank";

// Announcement Banner
export const IntelligentTestingAnnouncementClick: EventName =
  "announcement.intelligent-testing.click";
// Keeping old event names for reference (to look up on PostHog)
// export const LocalDevAnnouncementClick: EventName =
//   "announcement.local-dev.click";
// export const ChromeExtensionAnnouncementClick: EventName =
//   "announcement.chrome-extension.click";

// Page: Overview :: Get Started
export const ClickGetStartedConceptsVideo: EventName =
  "overview.get-started.videos.concept.click";
export const ClickGetStartedLocalDevVideo: EventName =
  "overview.get-started.videos.local-dev.click";
export const ClickGetStartedConceptsDocs: EventName =
    "overview.get-started.docs.concept.click";
export const ClickGetStartedYoutubeChannelLink: EventName =
  "overview.get-started.more.youtube-channel.click";
export const ClickGetStartedUserGuideLink: EventName =
  "overview.get-started.more.user-guide.click";
export const ClickGetStartedJoinSlackCommunityLink: EventName =
  "overview.get-started.join-slack-community.click";
export const ClickGetStartedScheduleADemoLink: EventName =
  "overview.get-started.schedule-a-demo.click";

// Page: Clusters
export const ClustersRequestPlaygroundCluster: EventName =
  "clusters.banner.request-playground-cluster";
export const ClustersOpenQuickstart: EventName =
  "clusters.banner.open-quickstart";

// Page: Create Sandbox
export const CreateSandboxPageOpenQuickstart: EventName =
  "create-sandbox.tip.open-quickstart";
export const CreateSandboxPageApplySandbox: EventName =
  "create-sandbox.apply-sandbox.click";
export const CreateSandboxPageViewSandboxDetail: EventName =
  "create-sandbox.apply-sandbox.open-sandbox-detail";

// Page: Sandboxes
export const SandboxesGotoCreateSandboxPage: EventName =
  "sandboxes.links.open-create-sandbox";

// Page: Sandbox Detail
export const SandboxDetailOpenSpecInEditor: EventName =
  "sandbox-detail.spec.view-in-editor";

// Page: Route Group Detail :: Tabs
export const RouteGroupDetailTabOverview: EventName =
  "routegroup-detail.tab.overview";
export const RouteGroupDetailTabSpecification: EventName =
  "routegroup-detail.tab.specification";

// Page: Resource Plugin Detail :: Tabs
export const ResourcePluginDetailTabOverview: EventName =
  "resourceplugin-detail.tab.overview";
export const ResourcePluginDetailTabSpecification: EventName =
  "resourceplugin-detail.tab.specification";

// Page: Sandbox Detail :: Tabs
export const SandboxDetailTabOverview: EventName =
  "sandbox-detail.tab.overview";
export const SandboxDetailTabSpecification: EventName =
  "sandbox-detail.tab.specification";
export const SandboxDetailTabExplore: EventName = "sandbox-detail.tab.explore";
export const SandboxDetailTabLogs: EventName = "sandbox-detail.tab.logs";

// Page: Sandbox Detail -> Explore
export const ExploreSendRequest: EventName = "sandbox.explore.send-request";
export const ExploreSendRequestToBaseline: EventName =
  "sandbox.explore.send-request-to-baseline";
export const ExploreCompareResponse: EventName =
  "sandbox.explore.compare-response";
export const ExploreSaveRequest: EventName = "sandbox.explore.save-request";

// Page: Runner Group Editor
export const RunnerGroupEditorPageApplyRunnerGroup: EventName =
  "runner-group-editor.apply.click";

// Page: Runner Group Detail
export const RunnerGroupDetailClickOverviewTab: EventName =
  "runner-group-detail.tabs.overview.click";
export const RunnerGroupDetailClickSpecificationTab: EventName =
  "runner-group-detail.tabs.specification.click";
export const RunnerGroupDetailClickJobsTab: EventName =
  "runner-group-detail.tabs.jobs.click";

// Page: Job Detail
export const JobDetailClickOverviewTab: EventName =
  "job-detail.tabs.overview.click";
export const JobDetailClickSpecificationTab: EventName =
  "job-detail.tabs.specification.click";
export const JobDetailClickArtifactsTab: EventName =
  "job-detail.tabs.artifacts.click";
export const JobDetailClickLogsTab: EventName = "job-detail.tabs.logs.click";
