import React, { useState } from "react";
import {
  Button,
  Card,
  ControlGroup,
  HTMLSelect,
  Intent,
  OverlayToaster,
} from "@blueprintjs/core";
import _ from "lodash";
import useApi from "../../hooks/UseApi";
import useApiMutation from "../../hooks/UseApiMutation";
import Loading from "../../components/Loading/Loading";
import Error from "../../components/Error/Error";
import Table from "../../components/Table/Table";
import Spacer from "../../components/Util/Util";
import { SdHeading1, SdHeading5 } from "../../components/theming/SdHeading";
import SdTheme from "../../styles/theme";
import { SdInputGroup } from "../../components/theming/SdInput";
import SdButton from "../../components/theming/SdButton";
import { useAuth } from "../../contexts/AuthContext";
import CopyableText from "../../components/CopyableText";
import VcsIntegration from "./VcsIntegration";

interface PreservedLabel {
  id: string;
  value: string;
  type: string;
}

interface FormattedPreservedLabel extends PreservedLabel {
  action: React.ReactNode;
}

interface PreservedLabelsResponse {
  labels: PreservedLabel[];
}

const toaster = OverlayToaster.create();

const columns = [
  {
    Header: "Id",
    accessor: "id",
    show: false,
  },
  {
    Header: "Match Expression",
    accessor: "value",
  },
  {
    Header: "Match Type",
    accessor: "type",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

function Settings() {
  const queryIdentifier = ["preserved-labels"];
  const [matchType, setMatchType] = useState("exact");
  const [matchText, setMatchText] = useState("");

  const { org } = useAuth().state;

  const { isLoading, isIdle, isSuccess, error, data } =
    useApi<PreservedLabelsResponse>(
      queryIdentifier,
      `/api/v1/orgs/:orgName/settings/preserved-labels`
    );
  const createLabelApi = useApiMutation(
    "create-preserved-label",
    "POST",
    queryIdentifier
  );
  const deleteLabelApi = useApiMutation(
    "delete-preserved-label",
    "DELETE",
    queryIdentifier
  );

  if (isLoading || isIdle) {
    return <Loading />;
  }
  if (error) {
    return <Error text={error.message} />;
  }

  if (createLabelApi.error) {
    toaster.show({
      message: _.get(
        createLabelApi.error,
        ["response", "data", "error"],
        "Unknown error."
      ),
      intent: Intent.DANGER,
    });
    createLabelApi.reset();
  } else if (createLabelApi.isSuccess) {
    toaster.show({
      message: `Created new preserved label`,
      intent: Intent.SUCCESS,
    });
    createLabelApi.reset();
    setMatchText("");
  }

  if (deleteLabelApi.error) {
    toaster.show({
      message: _.get(
        createLabelApi.error,
        ["response", "data", "error"],
        "Unknown error."
      ),
      intent: Intent.DANGER,
    });
    deleteLabelApi.reset();
  } else if (deleteLabelApi.isSuccess) {
    toaster.show({
      message: `Deleted preserved label`,
      intent: Intent.SUCCESS,
    });
    deleteLabelApi.reset();
  }

  const { labels } = data!;
  const formattedLabels: FormattedPreservedLabel[] = Array.isArray(labels)
    ? labels.map((label) => ({
        ...label,
        action: <Button minimal icon="trash" />,
      }))
    : [];

  const addLabel = () => {
    createLabelApi.mutate({
      url: `/api/v1/orgs/:orgName/settings/preserved-labels`,
      data: {
        type: matchType,
        value: matchText,
      },
    });
  };

  const deleteLabel = (row: FormattedPreservedLabel) => {
    deleteLabelApi.mutate({
      url: `/api/v1/orgs/:orgName/settings/preserved-labels/${row.id}`,
    });
  };

  return (
    <>
      <SdHeading1 small lightBackground>
        Settings
      </SdHeading1>
      <p
        style={{
          color: SdTheme.Text.lightBackground,
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        These settings are global.
      </p>
      <br />

      <Card className="mb-8">
        <SdHeading5 lightBackground>General</SdHeading5>
        <p style={{ color: SdTheme.Text.lightBackground }}>
          General settings associated with your Signadot Organization
        </p>
        <Spacer />
        Organization Name:
        <CopyableText className="p-2 h-8 ml-4" inline>
          {org?.name}
        </CopyableText>
      </Card>

      <Card className="mb-8">
        <SdHeading5 lightBackground>Preserve Labels</SdHeading5>
        <p style={{ color: SdTheme.Text.lightBackground }}>
          The labels that match will be copied from the baseline version of a
          pod to the new version of it created within a Signadot sandbox.
          <br /> Any changes made here will affect all running sandboxes as well
          as any new sandboxes that are created.
        </p>
        <Spacer />

        {isSuccess && !_.isEmpty(labels) && (
          <>
            <Table
              columns={columns}
              data={formattedLabels}
              onActionCallback={deleteLabel}
            />
            <Spacer />
            <Spacer />
          </>
        )}

        <ControlGroup>
          <HTMLSelect
            style={{ color: SdTheme.Text.lightBackground }}
            defaultValue="exact"
            onChange={(e) =>
              setMatchType((e.target as HTMLSelectElement).value)
            }
          >
            <option value="exact">exact</option>
            <option value="regex">regex</option>
          </HTMLSelect>
          <span className="w-2" />

          <SdInputGroup
            placeholder="label pattern..."
            onInput={(e) => setMatchText((e.target as HTMLInputElement).value)}
            value={matchText}
          />
          <span className="w-2" />

          <SdButton icon="plus" onClick={addLabel}>
            Add
          </SdButton>
        </ControlGroup>
      </Card>
      <VcsIntegration />
    </>
  );
}

export default Settings;
