import React from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import styles from "./ViewSpec.module.css";
import ActionLink from "../ActionLink";
import useOpenInEditorURL from "./hook";

type ViewSpecProps = {
  yaml: string;
  eventName?: string;
};
const ViewSpec: React.FC<ViewSpecProps> = ({ yaml, eventName }) => {
  const [openInEditorURL] = useOpenInEditorURL(yaml);
  return (
    <div className={styles.container}>
      <CodeEditor
        value={yaml}
        language="yaml"
        padding={15}
        disabled
        data-color-mode="light"
        className={styles.spec}
      />
      {eventName && (
        <ActionLink
          eventName={eventName}
          onClick={() => window.open(openInEditorURL)}
          className={styles.openInEditor}
        >
          Open in Editor
        </ActionLink>
      )}
    </div>
  );
};

export default ViewSpec;
