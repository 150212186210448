import React, { useEffect, useState } from "react";
import { Button } from "@blueprintjs/core";

interface Props {
  pageCount: number;
  onPageChange: (page: number) => void;
  currentPage: number;
}

export const ButtonGroup: React.FunctionComponent<Props> = ({
  pageCount,
  onPageChange,
  currentPage,
}) => {
  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  const renderButtons = (count: number) => {
    const arr = [];
    for (let i = 1; i <= count; i += 1) {
      arr.push(
        <Button
          className="mr-2"
          key={i}
          onClick={() => handlePageChange(i)}
          disabled={i === currentPage}
          outlined={false}
        >
          {i}
        </Button>
      );
    }
    return arr;
  };

  return (
    <div className="mt-5">
      <Button
        className="mr-2"
        onClick={() => handlePageChange(currentPage - 1)}
        outlined={false}
        disabled={currentPage === 1}
      >
        Previous
      </Button>
      {renderButtons(pageCount)}
      <Button
        className="mr-2"
        onClick={() => handlePageChange(currentPage + 1)}
        outlined={false}
        disabled={currentPage === pageCount}
      >
        Next
      </Button>
    </div>
  );
};

export default ButtonGroup;
