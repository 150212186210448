import React from "react";
import LogsPanel from "../../../../../../components/Logs";
import styles from "./logs.module.css";
import type {
  LogItem,
  LogResponseItem,
} from "../../../../../../components/Logs/Logs";
import type { Job } from "../../../../../../@types/sd/job";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { AttemptPhaseEnum } from "../../../../../../@types/sd/job.d.ts";
import type { StandardStreamType } from "./constants";
import { SIGNAL_RESTART, STDERR, STDOUT } from "./constants";
import useSSE from "../../../../../../hooks/UseSSE";

interface Props {
  job: Job;
}

const Logs: React.FC<Props> = ({ job }) => {
  // Collections to store the stdout and stderr logs
  const [stdoutLogs, setStdoutLogs] = React.useState<LogItem[]>();
  const [stderrLogs, setStderrLogs] = React.useState<LogItem[]>();

  // To switch between stdout and stderr logs
  const [selectedType, setSelectedType] =
    React.useState<StandardStreamType>(STDOUT);

  // Handle Messages sent by the server
  const onMessage = (
    message: LogResponseItem,
    setLogs: React.Dispatch<React.SetStateAction<LogItem[] | undefined>>
  ) =>
    setLogs((prevLogs) => [
      ...(prevLogs || []),
      {
        message: message.message,
      } as LogItem,
    ]);

  // Handle Signals sent by the server
  const onSignal = (
    signal: string,
    setLogs: React.Dispatch<React.SetStateAction<LogItem[] | undefined>>
  ) => {
    if (signal === SIGNAL_RESTART) {
      setLogs([] as LogItem[]);
    }
  };

  // Handle Error messages sent by the server
  const onError = (error: string) => {
    console.error("ERROR:", error);
  };

  const streamEndpoint = `/api/v2/orgs/:orgName/jobs/${job.name}/attempts/${job.status.attempts[0]?.id}/logs/stream`;

  const doDebounce = (phase?: AttemptPhaseEnum): boolean =>
    phase === AttemptPhaseEnum.SUCCEEDED || phase === AttemptPhaseEnum.FAILED;

  // Handle SSE events for stdout logs
  useSSE<LogResponseItem>(
    `${streamEndpoint}?type=${STDOUT}`,
    (message) => onMessage(message, setStdoutLogs),
    (message) => onSignal(message, setStdoutLogs),
    onError,
    doDebounce(job.status.attempts[0]?.phase)
  );

  // Handle SSE events for stderr logs
  useSSE<LogResponseItem>(
    `${streamEndpoint}?type=${STDERR}`,
    (message) => onMessage(message, setStderrLogs),
    (message) => onSignal(message, setStderrLogs),
    onError,
    doDebounce(job.status.attempts[0]?.phase)
  );

  const logs = React.useMemo(
    () => (selectedType === STDOUT ? stdoutLogs : stderrLogs),
    [stdoutLogs, stderrLogs, selectedType]
  );

  return (
    <LogsPanel
      logPanelOpen
      logs={logs}
      header={
        <div>
          <span className={styles.selectLogType}>Logs type:</span>{" "}
          <select
            className={styles.select}
            onChange={(event) => setSelectedType(event.target.value)}
          >
            <option>{STDOUT}</option>
            <option>{STDERR}</option>
          </select>
        </div>
      }
    />
  );
};

export default Logs;
