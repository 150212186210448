// eslint-disable-next-line import/prefer-default-export
export const formatContent = (content: string, contentType?: string) => {
  if (contentType === "json") {
    try {
      return JSON.stringify(JSON.parse(content), null, 2);
    } catch (e) {
      // no formatting
    }
  }
  return content;
};
