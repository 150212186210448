import type {
  HttpHeaders,
  RequestExecResponseData,
} from "../SavedRequestCatalog/types";

export const convertStringToHeaders = (headers: string): HttpHeaders => {
  const lines = headers.split("\n");
  const result: HttpHeaders = {};

  lines.forEach((line) => {
    const [name, value] = line.split(":").map((str) => str.trim());
    if (name && value) {
      if (!result[name]) {
        result[name] = [value];
      } else {
        result[name].push(value);
      }
    }
  });

  return result;
};

export const convertHeadersToString = (
  headers?: HttpHeaders
): string | undefined => {
  if (!headers) {
    return undefined;
  }
  return Object.entries(headers)
    .reduce((acc, [key, values]) => {
      const headerLines = values?.map((value) => `${key}: ${value}`);
      return [...acc, ...headerLines];
    }, [])
    .join("\n");
};

export const getContentType = (
  httpHeaders?: HttpHeaders
): string | undefined => {
  if (!httpHeaders) {
    return undefined;
  }
  const contentTypeKey = Object.keys(httpHeaders).find(
    (key) => key.toLowerCase() === "content-type"
  );

  if (contentTypeKey) {
    const contentTypeValues = httpHeaders[contentTypeKey];
    const isJson = contentTypeValues.some((value) =>
      value.toLowerCase().includes("application/json")
    );
    if (isJson) {
      return "json";
    }
  }

  return undefined;
};

export const getSummaryContent = (data?: RequestExecResponseData): string => {
  if (!data) {
    return "";
  }
  const summary = data.status;
  const headers = convertHeadersToString(data.headers);
  return `${summary}\n\nHEADERS:\n${headers}`;
};
