import React from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { formatContent } from "../utils";
import styles from "./ViewInEditor.module.css";
import type { PanelInfo } from "../InfoPanels";

interface Props {
  panelInfo: PanelInfo;
}

const ViewInEditor: React.FC<Props> = ({ panelInfo }) => (
  <CodeEditor
    value={formatContent(panelInfo.content || "", panelInfo.contentType)}
    padding={15}
    language={panelInfo.contentType}
    disabled={panelInfo.disabled}
    data-color-mode="light"
    className={styles.editor}
    placeholder={panelInfo.placeholder}
    onChange={(e) => panelInfo.onChange?.(e.target.value)}
  />
);

export default ViewInEditor;
