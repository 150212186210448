import React from "react";
import { GrAnnounce } from "react-icons/gr";

import BaseWidget from "../BaseWidget";
import type { AnnouncementData } from "./defaults";
import defaultItems from "./defaults";
import styles from "./Announcements.module.css";

interface Props {
  items?: AnnouncementData[];
}

const Announcements: React.FunctionComponent<Props> = ({ items = [] }) => {
  const announcements = [...items, ...defaultItems];
  if (announcements.length === 0) {
    return null;
  }
  return (
    <BaseWidget title="Announcements" iconName={<GrAnnounce size="1.1em" />}>
      <ul className={styles.list}>
        {announcements.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`item-${index}`}>{item.content}</li>
        ))}
      </ul>
    </BaseWidget>
  );
};

export default Announcements;
