import { useEffect, useRef, useState } from "react";
import { Cookies as CookiesController } from "react-cookie";

export const COOKIES_NAME = {
  COLLAPSE_SIDEBAR: "collapse-sidebar",
} as const;

type CookiesTypeMapping = {
  [COOKIES_NAME.COLLAPSE_SIDEBAR]: boolean;
};

type CookiesState = {
  [K in keyof typeof COOKIES_NAME as (typeof COOKIES_NAME)[K]]: CookiesTypeMapping[(typeof COOKIES_NAME)[K]];
};

export default () => {
  const cookiesCtrlRef = useRef(new CookiesController());

  const [cookies, setCookies] = useState<CookiesState>({
    [COOKIES_NAME.COLLAPSE_SIDEBAR]: false,
  });

  useEffect(() => {
    const updateCookies = () => {
      setCookies((prevState) => ({
        ...prevState,
        [COOKIES_NAME.COLLAPSE_SIDEBAR]:
          cookiesCtrlRef.current.get(COOKIES_NAME.COLLAPSE_SIDEBAR) === "true",
      }));
    };

    const intervalID = setInterval(updateCookies, 500);

    updateCookies();

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const setCookie = <K extends keyof CookiesState>(
    name: K,
    value: CookiesState[K] | ((val: CookiesState[K]) => CookiesState[K])
  ) => {
    if (typeof value === "function") {
      cookiesCtrlRef.current.set(name, value(cookies[name]));
      return;
    }

    cookiesCtrlRef.current.set(name, value);
  };

  return {
    values: cookies,
    setCookie,
  };
};
