import React from "react";
import { Icon } from "@blueprintjs/core";
import styles from "./FilterSelect.module.css";
import type { FilterOptionItem } from "../Filter/Filter";

type FilterSelectOptionProps = {
  option: FilterOptionItem;
  onClick: (status: boolean) => void;
  isSelected: boolean;
};

const FilterSelectOption = ({
  option,
  onClick,
  isSelected,
}: FilterSelectOptionProps) => {
  const isDisabled = typeof option !== "string" && option.disabled;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={`${styles.select_item} ${isSelected ? styles.selected : ""} ${
        isDisabled ? styles.disabled : ""
      }`}
      onClick={() => !isDisabled && onClick(!isSelected)}
    >
      {isSelected && <Icon icon="small-tick" />}
      {typeof option === "string" ? option : option.label}
    </div>
  );
};

type FilterSelectProps = {
  options: FilterOptionItem[];
  onChange: (option: FilterOptionItem | undefined) => void;
  selectedValue?: FilterOptionItem;
};
const FilterSelect = ({
  options,
  onChange,
  selectedValue,
}: FilterSelectProps) => (
  <div className={styles.select}>
    {options.map((o) => (
      <FilterSelectOption
        option={o}
        key={typeof o === "string" ? o : o.label}
        onClick={(status) => onChange(status ? o : undefined)}
        isSelected={o === selectedValue}
      />
    ))}
  </div>
);

export default FilterSelect;
