/* eslint-disable react/display-name, jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { Icon } from "@blueprintjs/core";
import { SiGoogleanalytics, SiKubernetes } from "react-icons/si";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { useAuth0 } from "@auth0/auth0-react";
import { GiPlug } from "react-icons/gi";
import { TbServerBolt } from "react-icons/tb";
import type { User } from "../../../contexts/AuthContext";
import styles from "./Sidebar.module.css";
import ActionLink from "../../theming/ActionLink";
import { LogoIcon } from "../../theming/icons";
import NavItem from "./NavItem";
import HistoryLink from "../../HistoryLink";
import BillingPlanBadge from "../BillingPlanBadge";
import { useWindowsResizeThreshold } from "../../../hooks/UseWindowsResizeThreshold";
import SidebarSection from "./SidebarSection";
import useCookies, { COOKIES_NAME } from "../../../hooks/UseCookies";

const MIN_COLLAPSED_SIDEBAR_PX = 1024;

interface Org {
  name: string;
  displayName: string;
  billingTier: number;
  user: User;
}

interface Props {
  org?: Org;
}

export const Sidebar = (props: Props) => {
  const { org } = props;
  const { user } = useAuth0();
  const provider = user?.sub?.split("|")[0];

  const {
    values: { [COOKIES_NAME.COLLAPSE_SIDEBAR]: userCollapseSidebar },
    setCookie,
  } = useCookies();
  const isNarrowScreen = useWindowsResizeThreshold(MIN_COLLAPSED_SIDEBAR_PX);

  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (isNarrowScreen) {
      setIsCollapsed(true);
      return;
    }

    setIsCollapsed(userCollapseSidebar);
  }, [isNarrowScreen, userCollapseSidebar]);

  return (
    <>
      {/* Sidebar */}
      <div
        className={`${styles.container} ${
          isCollapsed ? styles.collapsed : ""
        } overflow-auto`}
      >
        {!isCollapsed && (
          <div className={styles.orgInfo}>
            <div className={styles.orgName}>
              {org!.displayName || org!.name}
            </div>
            <HistoryLink url="/billing">
              <BillingPlanBadge />
            </HistoryLink>
          </div>
        )}
        <div className={styles.list}>
          <NavItem
            icon="dashboard"
            name="Overview"
            url="/"
            collapsed={isCollapsed}
          />
          <NavItem
            icon="layers"
            name="Sandboxes"
            url="/sandboxes"
            collapsed={isCollapsed}
          />
          <NavItem
            icon={<GiPlug size="1.2rem" />}
            name="Resource Plugins"
            url="/resource-plugins"
            collapsed={isCollapsed}
          />
          <NavItem
            icon="layout"
            name="Route Groups"
            url="/routegroups"
            collapsed={isCollapsed}
          />
          <SidebarSection title="Testing" isCollapsed={isCollapsed} defaultOpen>
            <NavItem
              icon={<TbServerBolt />}
              name="Job Runner Groups"
              url="/testing/job-runner-groups"
              collapsed={isCollapsed}
              isChild
            />
            <NavItem
              icon="lab-test"
              name="Jobs"
              url="/testing/jobs"
              collapsed={isCollapsed}
              isChild
            />
          </SidebarSection>
          <SidebarSection
            title="Administration"
            isCollapsed={isCollapsed}
            defaultOpen
          >
            {provider !== "github" && (
              <NavItem
                icon="people"
                name="Users"
                url="/users"
                collapsed={isCollapsed}
                isChild
              />
            )}
            <NavItem
              icon={<SiKubernetes size="1.1em" />}
              name="Clusters"
              url="/settings/clusters"
              collapsed={isCollapsed}
              isChild
            />
            <NavItem
              icon="key"
              name="API Keys"
              url="/settings/apikeys"
              collapsed={isCollapsed}
              isChild
            />
            <NavItem
              icon={<SiGoogleanalytics size="1.1em" />}
              name="Analytics"
              url="/analytics"
              collapsed={isCollapsed}
              isChild
            />
            <NavItem
              icon="cog"
              name="Settings"
              url="/settings/global"
              collapsed={isCollapsed}
              isChild
            />
            <NavItem
              icon="credit-card"
              name="Billing"
              url="/billing"
              collapsed={isCollapsed}
              isChild
            />
          </SidebarSection>
        </div>

        <footer className="mt-auto">
          {isCollapsed ? (
            <LogoIcon className={styles.logo} />
          ) : (
            <div className={styles.brand}>signadot</div>
          )}
          <div className={styles.resize}>
            <ActionLink
              onClick={() => {
                setCookie(COOKIES_NAME.COLLAPSE_SIDEBAR, (val) => !val);
              }}
            >
              {isCollapsed ? (
                <Icon icon="double-chevron-right" />
              ) : (
                <>
                  <Icon icon="double-chevron-left" /> Collapse
                </>
              )}
            </ActionLink>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Sidebar;
