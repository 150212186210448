import React from "react";
import { cloneDeep } from "lodash";
import type { TabId } from "@blueprintjs/core";
import { Intent, OverlayToaster } from "@blueprintjs/core";
import type {
  HttpHeaders,
  RequestData,
  SavedRequest,
  SupportedHttpMethod,
} from "../SavedRequestCatalog/types";
import {
  HTTP_PROTOCOL,
  SUPPORTED_HTTP_METHODS,
} from "../SavedRequestCatalog/types";
import styles from "./ExplorePanel.module.css";
import {
  convertHeadersToString,
  convertStringToHeaders,
  getContentType,
  getSummaryContent,
} from "./utils";
import type { PanelInfo } from "./InfoPanels/InfoPanels";
import InfoPanels from "./InfoPanels/InfoPanels";
import {
  ExploreCompareResponse,
  ExploreSaveRequest,
  ExploreSendRequest,
  ExploreSendRequestToBaseline,
} from "../../../Constants";
import type { SandboxV2 } from "../../../@types/sd/sandboxv2";
import SaveRequestDialog from "./SaveRequestDialog";
import SelectService from "./SelectService";
import ButtonGroup from "../../../components/theming/button/ButtonGroup";
import {
  useExecuteRequest,
  useSavedRequestByIdApi,
  useSaveRequest,
} from "../hooks/hooks";
import ActionLink from "../../../components/theming/ActionLink";
import { useCompareResponses } from "../hooks/compare";
import ViewInEditor from "./InfoPanels/ViewInEditor";
import ViewDiff from "./InfoPanels/ViewDiff";

interface Props {
  selectedRequestPartial?: SavedRequest;
  sandbox: SandboxV2;
}

// eslint-disable-next-line no-shadow
enum View {
  Response,
  Compare,
}

// eslint-disable-next-line no-shadow
enum Tabs {
  RequestHeaders = "request-headers",
  RequestBody = "request-body",
  ResponseSummary = "response-summary",
  ResponseBody = "response-body",
  CompareResponses = "compare-responses",
}

const toaster = OverlayToaster.create();

const ExplorePanel: React.FC<Props> = ({ selectedRequestPartial, sandbox }) => {
  const [debug] = React.useState<boolean>(false); // TODO: Only for development purpose
  const [view, setView] = React.useState<View>(View.Response);

  // State management for handling REQUEST panel tab changes
  const [selectedRequestTabId, setSelectedRequestTabId] = React.useState<TabId>(
    Tabs.RequestHeaders
  );

  // State management for handling RESPONSE panel tab changes
  const [selectedResponseTabId, setSelectedResponseTabId] =
    React.useState<TabId>(Tabs.ResponseSummary);

  // 1: selectedRequestPartial is set if the user selects a saved template.
  // This doesn't hold complete information about the saved request. So we'll make another call
  // to fetch the complete detail.
  const savedRequestId = selectedRequestPartial?.id;
  // TODO: Handle data fetch error

  // 2: selectedRequest is obtained by making a separate API call, and holds complete information
  // on the selected request. This will not change when the user updates the request information
  // on the page.
  const { data: selectedRequest } = useSavedRequestByIdApi(savedRequestId);

  const emptyRequestTemplate = {
    request: {
      method: SUPPORTED_HTTP_METHODS[0],
      protocol: HTTP_PROTOCOL,
      headers: {} as HttpHeaders,
      requestURI: "",
      body: "",
      host: "",
    } as RequestData,
  } as SavedRequest;

  // 3. Construct requestTemplate as a deep clone of `selectedRequest`.
  // Any updates to the request on the ExplorePanel will be applied to the requestTemplate but
  // not selectedRequest. Keeping a separate copy of selectedRequest helps us to identify any
  // changes made by the user post saved template selection (by comparing with selectedResult).
  // If there are any changes, we can let the user save/upsert the changes.
  const [requestTemplate, setRequestTemplate] =
    React.useState<SavedRequest>(emptyRequestTemplate);

  // Save Request
  const saveRequestApi = useSaveRequest(requestTemplate?.request);
  const [isSaveDialogOpen, setIsSaveDialogOpen] =
    React.useState<boolean>(false);
  const handleSaveRequest = () => {
    const requestName = requestTemplate?.name;
    saveRequestApi.submit(requestName);
  };
  if (saveRequestApi.error) {
    toaster.show({
      message: saveRequestApi.error,
      intent: Intent.DANGER,
    });
    saveRequestApi.reset();
  }

  // Execute Request
  const execRequestApi = useExecuteRequest(requestTemplate?.request);
  if (execRequestApi.error) {
    toaster.show({
      message: execRequestApi.error,
      intent: Intent.DANGER,
    });
    execRequestApi.reset();
  }
  const sendToSandbox = () => {
    execRequestApi.sendToSandbox(sandbox.name);
    setView(View.Response);
  };
  const sendToBaseline = () => {
    execRequestApi.sendToBaseline(sandbox.spec.cluster);
    setView(View.Response);
  };

  const baselineRequest = useExecuteRequest(requestTemplate.request);
  const sandboxRequest = useExecuteRequest(requestTemplate.request);
  const compareResponsesApi = useCompareResponses(
    sandbox.name,
    sandbox.spec.cluster,
    baselineRequest,
    sandboxRequest
  );

  const compareResponses = () => {
    compareResponsesApi.submit?.();
    setView(View.Compare);
    setSelectedResponseTabId(Tabs.CompareResponses);
  };

  const reset = () => {
    execRequestApi.reset();
    saveRequestApi.reset();
    compareResponsesApi.reset?.();
    setView(View.Response);
    setSelectedResponseTabId(Tabs.ResponseSummary);
  };

  // Update current template with selected template
  React.useEffect(() => {
    if (selectedRequest) {
      setRequestTemplate(cloneDeep(selectedRequest));
      reset();
    }
  }, [selectedRequest]);

  // `headersString` is the canonical form for header on the UI. Example:
  // Header object:
  // {"Accept-Language": ["en-US,en;q=0.5", "de;q=0.3"], "Content-Type": ["application/json"]}
  //
  // Text equivalent:
  // Accept-Language: en-US,en;q=0.5
  // Accept-Language: de;q=0.3
  // Content-Type: application/json
  const [headersString, setHeadersString] = React.useState<string | undefined>(
    undefined
  );
  React.useEffect(() => {
    const headersStr = convertHeadersToString(
      selectedRequest?.request?.headers
    );
    setHeadersString(headersStr);
  }, [selectedRequest]);

  const updateRequestTemplate = (updatedRequestData: RequestData) => {
    const newRequestTemplate = {
      ...requestTemplate,
      request: updatedRequestData,
    } as SavedRequest;
    setRequestTemplate(newRequestTemplate);
  };

  const updateRequestName = (name: string) => {
    const newRequestTemplate = {
      ...requestTemplate,
      name,
    } as SavedRequest;
    setRequestTemplate(newRequestTemplate);
  };

  // Update Endpoint
  const handleEndpointUpdate = (protocol: string, hostname: string) => {
    if (!requestTemplate) return;
    updateRequestTemplate({
      ...requestTemplate.request,
      protocol: protocol.toUpperCase(),
      host: hostname,
    } as RequestData);
  };

  // Update Request URI
  const handleRequestUriUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!requestTemplate) return;
    updateRequestTemplate({
      ...requestTemplate.request,
      requestURI: e.target.value,
    } as RequestData);
  };

  // Update Method
  const handleMethodUpdate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!requestTemplate) return;
    updateRequestTemplate({
      ...requestTemplate.request,
      method: e.target.value as SupportedHttpMethod,
    } as RequestData);
  };

  // Update Body
  const handleBodyUpdate = (value?: string) => {
    if (!requestTemplate) return;
    updateRequestTemplate({
      ...requestTemplate.request,
      body: value,
    } as RequestData);
  };

  // Update Headers
  const handleHeadersChange = (value?: string) => {
    setHeadersString(value);

    const newHeaders = convertStringToHeaders(value || "");
    if (!requestTemplate) return;
    updateRequestTemplate({
      ...requestTemplate?.request,
      headers: newHeaders,
    } as RequestData);
  };

  React.useEffect(() => {
    setSelectedResponseTabId(
      execRequestApi.data?.body ? Tabs.ResponseBody : Tabs.ResponseSummary
    );
  }, [execRequestApi.data?.body]);

  return (
    <div>
      <SaveRequestDialog
        name={requestTemplate?.name}
        setName={(name: string) => updateRequestName(name)}
        showDialog={isSaveDialogOpen}
        setShowDialog={setIsSaveDialogOpen}
        handleSaveRequest={handleSaveRequest}
      />
      {debug && (
        <div>
          <div>
            selectedRequestPartial:{" "}
            {selectedRequestPartial && JSON.stringify(selectedRequestPartial)}
          </div>
          <div>
            selectedRequest:{" "}
            {selectedRequest && JSON.stringify(selectedRequest)}
          </div>
          <div>
            RequestTemplate:{" "}
            {requestTemplate && JSON.stringify(requestTemplate)}
          </div>
          <div>headersString: {headersString}</div>
        </div>
      )}
      <div className={styles.top}>
        <div className={styles.left}>
          <div className={styles.host}>
            <SelectService
              clusterName={sandbox.spec.cluster}
              onChange={handleEndpointUpdate}
              selectedProtocol={requestTemplate.request?.protocol}
              selectedHost={requestTemplate?.request?.host}
            />
          </div>
          <div className={styles.methodPath}>
            <select
              value={requestTemplate?.request?.method}
              onChange={(e) => handleMethodUpdate(e)}
              className={styles.select}
            >
              {SUPPORTED_HTTP_METHODS.map((method) => (
                <option key={method} value={method}>
                  {method}
                </option>
              ))}
            </select>
            <input
              type="text"
              value={requestTemplate?.request?.requestURI}
              onChange={handleRequestUriUpdate}
              className={`${styles.inputText} ${styles.path}`}
              placeholder="/path"
            />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.buttons}>
            <ButtonGroup
              allButtonProps={[
                {
                  id: "send-request",
                  eventName: ExploreSendRequest,
                  onClick: () => sendToSandbox(),
                  disabled: execRequestApi.isLoading,
                  label: "Send Request",
                },
                {
                  id: "send-request-to-baseline",
                  eventName: ExploreSendRequestToBaseline,
                  onClick: () => sendToBaseline(),
                  disabled: execRequestApi.isLoading,
                  label: "Send Request to Baseline",
                },
                {
                  id: "compare-response",
                  eventName: ExploreCompareResponse,
                  onClick: () => compareResponses(),
                  disabled: execRequestApi.isLoading,
                  label: "Compare Fork vs Baseline response",
                },
              ]}
            />
          </div>
          <div className={styles.links}>
            <ActionLink
              onClick={() => setIsSaveDialogOpen(true)}
              eventName={ExploreSaveRequest}
              disabled={saveRequestApi.isLoading}
            >
              Save
            </ActionLink>
            <ActionLink
              onClick={() => {
                setRequestTemplate(emptyRequestTemplate);
                reset();
              }}
            >
              Clear
            </ActionLink>
          </div>
        </div>
      </div>
      <div className={styles.panels}>
        <div className={styles.panel}>
          <InfoPanels
            title="Request"
            selectedTabIndex={selectedRequestTabId}
            onTabChange={setSelectedRequestTabId}
            panelInfos={[
              {
                id: Tabs.RequestHeaders,
                title: "Headers",
                disabled: false,
                content: convertHeadersToString(
                  requestTemplate?.request?.headers
                ),
                placeholder: `header1: value1\nheader2: value2\n`,
                onChange: handleHeadersChange,
                render: (panelInfo: PanelInfo) => (
                  <ViewInEditor panelInfo={panelInfo} />
                ),
              } as PanelInfo,
              {
                id: Tabs.RequestBody,
                title: "Body",
                disabled: false,
                content: requestTemplate?.request?.body || "",
                contentType: getContentType(requestTemplate?.request?.headers),
                onChange: handleBodyUpdate,
                render: (panelInfo: PanelInfo) => (
                  <ViewInEditor panelInfo={panelInfo} />
                ),
              } as PanelInfo,
            ]}
          />
        </div>
        <div className={styles.panel}>
          <InfoPanels
            title="Response"
            selectedTabIndex={selectedResponseTabId}
            onTabChange={setSelectedResponseTabId}
            panelInfos={(() => {
              if (view === View.Response) {
                return [
                  {
                    id: Tabs.ResponseSummary,
                    title: "Summary",
                    disabled: true,
                    content: getSummaryContent(execRequestApi.data),
                    render: (panelInfo: PanelInfo) => (
                      <ViewInEditor panelInfo={panelInfo} />
                    ),
                  } as PanelInfo,
                  {
                    id: Tabs.ResponseBody,
                    title: "Body",
                    disabled: true,
                    content: execRequestApi.data?.body ?? "",
                    contentType: getContentType(execRequestApi.data?.headers),
                    render: (panelInfo: PanelInfo) => (
                      <ViewInEditor panelInfo={panelInfo} />
                    ),
                  } as PanelInfo,
                ];
              }
              return [
                {
                  id: Tabs.CompareResponses,
                  title: "Diff",
                  disabled: true,
                  render: () => (
                    <ViewDiff
                      baselineBody={
                        compareResponsesApi.data?.baselineData.body || ""
                      }
                      sandboxBody={
                        compareResponsesApi.data?.sandboxData.body || ""
                      }
                    />
                  ),
                } as PanelInfo,
              ];
            })()}
          />
        </div>
      </div>
    </div>
  );
};

export default ExplorePanel;
