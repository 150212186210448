import React from "react";
import type { IconName } from "@blueprintjs/icons";
import type { MaybeElement } from "@blueprintjs/core";
import { Icon, Intent } from "@blueprintjs/core";
import { useLocation, useNavigate } from "react-router-dom";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import styles from "./NavItem.module.css";

interface NavItemProps {
  icon: IconName | MaybeElement | string;
  name: string;
  url: string;
  collapsed: boolean;
  isChild?: boolean;
}

const NavItem: React.FunctionComponent<NavItemProps> = ({
  icon,
  name,
  url,
  collapsed,
  isChild,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={`${styles.navItem} ${
        location.pathname === url ? styles.selected : ""
      }`}
      onClick={() => {
        if (url.startsWith("https")) {
          window.open(url, "_blank")!.focus();
        } else {
          navigate(url);
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className="ml-4" />
      {collapsed ? (
        <Popover2>
          <Tooltip2
            content={name}
            intent="none"
            placement="top"
            hoverOpenDelay={250}
          >
            <Icon
              icon={icon as IconName | MaybeElement}
              size={collapsed ? 20 : 16}
              intent={Intent.NONE}
            />
          </Tooltip2>
        </Popover2>
      ) : (
        <div className={isChild ? "pl-4" : ""}>
          <Icon
            icon={icon as IconName | MaybeElement}
            size={collapsed ? 20 : 16}
            intent={Intent.NONE}
          />
        </div>
      )}
      {!collapsed ? <div className="mx-5">{name}</div> : ""}
    </div>
  );
};

export default NavItem;
