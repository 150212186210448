import type { ReactNode } from "react";
import React from "react";
import Pluralize from "pluralize";
import styles from "./ExpandableList.module.css";

const DEFAULT_ITEM_COUNT = 5;

interface ExpandableListProps {
  items?: ReactNode[];
  defaultCount: number;
  className?: string;
  showMoreLabel?: string;
}

const ExpandableList: React.FC<ExpandableListProps> = ({
  items,
  defaultCount = DEFAULT_ITEM_COUNT,
  className,
  showMoreLabel,
}) => {
  const [displayExpanded, setDisplayExpanded] = React.useState(false);
  if (!items || items.length === 0) {
    return null;
  }
  const len = items.length;
  return (
    <ul className={`${className || ""} ${styles.container}`}>
      {items.slice(0, Math.min(defaultCount, len))}
      {len > defaultCount && !displayExpanded && (
        <button
          className={styles.showMoreBtn}
          type="button"
          onClick={() => setDisplayExpanded(true)}
        >
          {showMoreLabel ||
            `... and ${len - defaultCount} ${Pluralize(
              "other",
              len - defaultCount
            )}`}
        </button>
      )}
      {displayExpanded && items.slice(defaultCount, len)}
      {len > defaultCount && displayExpanded && (
        <button
          className={styles.showMoreBtn}
          type="button"
          onClick={() => setDisplayExpanded(false)}
        >
          (show less)
        </button>
      )}
    </ul>
  );
};

export default ExpandableList;
