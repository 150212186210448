import React, { useEffect, useMemo, useState } from "react";
import type { FilterOptions } from "../Filter";
import Filter from "../Filter";
import style from "./Filters.module.css";

type FiltersProps<T> = {
  filters: FilterOptions<T>[];
  data: T[];
  onChange: (newData: T[]) => void;
};

const Filters = <T,>({ filters, data, onChange }: FiltersProps<T>) => {
  const [usedFilters, setUsedFilters] = useState<
    (FilterOptions<T> & { value: string })[]
  >([]);

  const filterData = (filtersToApply: typeof usedFilters) => {
    const filteredData = data.filter((row) =>
      filtersToApply.every((f) => f.filter(row, f.value))
    );

    onChange(filteredData);
  };
  const handleFilterChange =
    (filter: FilterOptions<T>) => (selectedOption: string | undefined) => {
      const filtered = usedFilters.filter((f) => f.label !== filter.label);

      if (selectedOption) {
        filtered.push({ ...filter, value: selectedOption });
      }

      setUsedFilters(filtered);

      filterData(filtered);
    };

  const selectedValuesFilters = useMemo(
    () =>
      usedFilters.reduce(
        (parsedFilters, currentFilter) => ({
          ...parsedFilters,
          [currentFilter.label]: currentFilter.value,
        }),
        {}
      ),
    [usedFilters]
  );

  useEffect(() => {
    filterData(usedFilters);
  }, [data]);

  return (
    <div className={style.container}>
      {filters.map((f) => (
        <Filter
          onChange={handleFilterChange(f)}
          key={f.label}
          {...f}
          selectedValue={selectedValuesFilters[f.label]}
        />
      ))}
    </div>
  );
};

export default Filters;
