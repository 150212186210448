import { Popover2 } from "@blueprintjs/popover2";
import React, { useState } from "react";
import { Icon } from "@blueprintjs/core";
import style from "./Filter.module.css";
import SdInput from "../../theming/SdInput";
import FilterSelect from "../FilterSelect";
import useDisclosure from "../../../hooks/UseDisclosure";

export type FilterOptionItem =
  | string
  | {
      label: string;
      disabled: boolean;
    };

export type FilterOptions<T> = {
  label: string;
  options: FilterOptionItem[];
  filter: (data: T, filterValue: string) => boolean;
};

type FilterProps<T> = {
  onChange: (selectedOption: FilterOptionItem | undefined) => void;
  selectedValue?: string;
} & FilterOptions<T>;

const Filter = <T,>({
  label,
  options,
  onChange,
  selectedValue,
}: FilterProps<T>) => {
  const [filterText, setFilterText] = useState("");

  const filteredOptions = options.filter((opt) => {
    if (typeof opt === "string") return opt.includes(filterText);

    return opt.label.includes(filterText);
  });

  const filterState = useDisclosure(false);

  return (
    <Popover2
      isOpen={filterState.isOpen}
      onClose={filterState.close}
      onInteraction={filterState.open}
      content={
        <div className={style.container}>
          <div className={style.menu_item}>
            <p className={style.menu_title}>Filter by {label.toLowerCase()}</p>

            <SdInput
              name="search-field"
              type="text"
              placeholder="Type to search"
              className="bp4-input bp4-fill bp4-medium mb-2"
              autoFocus
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
          <FilterSelect
            options={filteredOptions}
            onChange={(selectedOption) => {
              filterState.close();
              setFilterText("");
              onChange(selectedOption);
            }}
            selectedValue={selectedValue}
          />
        </div>
      }
      placement="bottom-end"
      renderTarget={({ isOpen, ...targetProps }) => (
        <div
          {...targetProps}
          className={`${style.trigger} ${
            selectedValue ? style.filterApplied : ""
          }`}
        >
          <div>{label}</div>
          <Icon icon={isOpen ? "chevron-up" : "chevron-down"} />
        </div>
      )}
    />
  );
};

export default Filter;
